import * as React from "react";
import constants from "constants/index";
import { graphql } from "gatsby";
import Styled from "styled-components/macro";
import Meta from "../../components/meta";
import Layout from "../../components/layout/Layout";
import SectionLeftOverGreyBackground from "../../components/pages/solutions/SectionLeftOverGreyBackground";
import HeroBigTextBanner from "../../components/pages/solutions/HeroBigTextBanner";
import SectionWithRightVideoMockup from "../../components/pages/solutions/SectionWithRightVideoMockup";
import HomeFeatured from "../../components/pages/home/HomeFeatured";
import HomeExamples from "../../components/pages/home/HomeExamples";
import Shoutout from "../../components/pages/signup/Shoutout";
import Content from "../../components/layout/Content/Content";
import FAQSection from "../../components/pages/faq/FAQSection";
import BlogHighlight from "../../components/pages/blog/BlogHighlight";
import SectionWithVerticalVideo from "../../components/pages/solutions/SectionWithVerticalVideo";

import blogThumbnail1 from "../../components/pages/blog/BlogThumbnails/What-is-a-mockup-Mockups-explained.png";
import blogThumbnail2 from "../../components/pages/blog/BlogThumbnails/4-Ways-to-Create-Website-Mockups -Even-if-You-Have-No-Design-Experience.png";
import blogThumbnail3 from "../../components/pages/blog/BlogThumbnails/Startup-Product-Mockups-The-Ultimate-Guide-for-Creating-Pitch-Ready-Designs.png";

const ContentExtended = Styled(Content)`
  margin-top: 9rem;
`;

const Home = ({ data }) => (
  <Layout>
    <Meta
      title="UI Mockup Tool | Design UX/UI Mockups | Uizard"
      description="UI mockup design for apps, web pages, and digital products with Uizard. It has never been easier to create stunning UX/UI mockups."
      url="/mockups/"
      faqschema='{
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "What are UI mockup tools?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "A UI mockup tool allows users to design webpages or apps and subsequently populate these mockups with assets and imagery. Some UX/UI mockup tools then allow the user to advance their mockup on to the functional prototype stage of design."
            }
          },
          {
            "@type": "Question",
            "name": "What are the best UI mockup tools for app mockups?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "There are many app mockup tools on the market however, which app mockup tool is best will largely come down to the user&apos;s level of design skill. User friendly mockup tools like Uizard are a great option for non-designers and those with limited experience in design, whereas UX/UI mockup tools such as Figma tend to be used by experienced, advanced designers."
            }
          },
          {
            "@type": "Question",
            "name": "What are the best UI mockup tools for web mockups?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Which UI mockup tool is best for web mockup depends largely on the user&apos;s skill with design practices. Software such as Uizard is the best option for people with less or no design experience while experienced designers tend to use highly technical UX/UI mockup tools such as Figma."
            }
          },
          {
            "@type": "Question",
            "name": "How much do UI mockup tools cost?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "The price of UI mockup tools varies however, paid plans can range anything from 10 USD monthly to upwards of 200 USD a month. Some UX/UI mockup tools, such as Uizard, do however offer free plans for users, with optional upgrade to paid plans to unlock extra features."
            }
          }
        ]
      }'
    />

    <HeroBigTextBanner
      tagline="UI Mockup Tool"
      headline="Mockup design made easy"
      description="UI mockup design for mobile apps, web pages, and digital products made easy with our drag-and-drop editor."
      imageA={data.mockupsCoverBig.childImageSharp}
      imageB={data.mockupsCoverSmall.childImageSharp}
      imageAlt="overview of a few screens designed in uizard"
      link={`https://${constants.APP_DOMAIN}/auth/sign-up`}
      cta="Sign up for free"
      tagAsTitle={true}
    />

    <SectionWithVerticalVideo
      headline="UI Mockups for non-designers"
      description="Our mission is the democratization of design. With Uizard's intuitive UI mockup tool, you can design your own mobile app mockup or web design; collaborate with your team; and share your <a:https://uizard.io/ux-design/>UX design</a> with key stakeholders with a click of a button."
      imageA={data.mockupsTeamworkBig.childImageSharp}
      imageB={data.mockupsTeamworkSmall.childImageSharp}
      imageAlt="multiple users using uizard for real-time collaboration"
      videoName="uizard-mockups-teamwork"
    />

    <SectionWithRightVideoMockup
      headline="Bring your mockup to life, rapidly"
      description="Creating UX/UI mockups has never been so quick and simple. Whether you are working from a sketch or creating a mockup from scratch with dynamic assets, Uizard empowers you to realize your design in minutes."
      imageA={data.mockupsRapidMockupBig.childImageSharp}
      imageB={data.mockupsRapidMockupSmall.childImageSharp}
      videoName="uizard-mockups-rapid-mockup"
      imageAlt="Visual showing users using drag-and-drop features in Uizard to design a website screen"
    />

    <SectionLeftOverGreyBackground
      headline="Streamline your mockup designs with our UI templates"
      description="Introducing... Uizard UI templates. With our <a:https://uizard.io/templates/mobile-app-templates/>app design templates</a>, no design experience is required. Simply open one of our mobile app, web app, or web design templates, tweak it to suit your MVP or service, and share it with your team."
      imageA={data.mockupsDesignTemplatesBig.childImageSharp}
      imageB={data.mockupsDesignTemplatesSmall.childImageSharp}
      imageAlt="Image showcasing a few screens available as Uizard templates"
      link="/templates/"
    />

    <HomeExamples />
    <HomeFeatured />

    <BlogHighlight
      title="Learn UX/UI mockup design"
      blogs={[
        {
          image: blogThumbnail1,
          link: "https://uizard.io/blog/what-is-a-mockup-mockups-explained/",
          alt: "Thumbnail for blog titled What is a mockup? Mockups explained",
        },
        {
          image: blogThumbnail2,
          link: "https://uizard.io/blog/4-ways-to-create-a-website-design-mock-up-even-if-you-have-no-design-experience/",
          alt: "Thumbnail for blog titled 4 Ways to Create Website Mockups (Even if You Have No Design Experience)",
        },
        {
          image: blogThumbnail3,
          link: "https://uizard.io/blog/startup-product-mockups-the-ultimate-guide-for-creating-pitch-ready-designs/",
          alt: "Thumbnail for blog titled Startup Product Mockups: The Ultimate Guide for Creating Pitch-Ready Designs",
        },
      ]}
    />
    <FAQSection
      title={"Mockup FAQs"}
      questions={[
        {
          question: "What are UI mockup tools?",
          answer:
            "A UI mockup tool allows users to design webpages or apps and subsequently populate these mockups with assets and imagery. Some UX/UI mockup tools then allow the user to advance their mockup on to the functional prototype stage of design.",
        },
        {
          question: "What are the best UI mockup tools for app mockups?",
          answer:
            "There are many app mockup tools on the market however, which app mockup tool is best will largely come down to the user's level of design skill. User friendly mockup tools like Uizard are a great option for non-designers and those with limited experience in design, whereas UX/UI mockup tools such as Figma tend to be used by experienced, advanced designers.",
        },
        {
          question: "What are the best UI mockup tools for web mockups?",
          answer:
            "Which UI mockup tool is best for web mockup depends largely on the user's skill with design practices. Software such as Uizard is the best option for people with less or no design experience while experienced designers tend to use highly technical UX/UI mockup tools such as Figma.",
        },
        {
          question: "How much do UI mockup tools cost?",
          answer:
            "The price of UI mockup tools varies however, paid plans can range anything from 10 USD monthly to upwards of 200 USD a month. Some UX/UI mockup tools, such as Uizard, do however offer free plans for users, with optional upgrade to paid plans to unlock extra features.",
        },
      ]}
    />

    <ContentExtended>
      <Shoutout
        headline="UI Mockup design made easy"
        description="Turn your ideas into UX/UI mockups in minutes"
        cta="Sign up for free"
        imageA={data.shoutoutImageA.childImageSharp}
      />
    </ContentExtended>
  </Layout>
);

export default Home;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    mockupsCoverBig: file(
      relativePath: { eq: "mockups/Mockups_Cover_2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    mockupsCoverSmall: file(
      relativePath: { eq: "mockups/Mockups_Cover_1x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 460, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    mockupsTeamworkBig: file(
      relativePath: { eq: "mockups/Mockups_Teamwork_2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1740, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    mockupsTeamworkSmall: file(
      relativePath: { eq: "mockups/Mockups_Teamwork_1x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 870, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    mockupsRapidMockupBig: file(
      relativePath: { eq: "mockups/Mockups_RapidMockup_2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1290, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    mockupsRapidMockupSmall: file(
      relativePath: { eq: "mockups/Mockups_RapidMockup_1x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 645, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    mockupsDesignTemplatesBig: file(
      relativePath: { eq: "mockups/Mockups_DesignTemplates2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 854, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    mockupsDesignTemplatesSmall: file(
      relativePath: { eq: "mockups/Mockups_DesignTemplates1x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 427, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    shoutoutImageA: file(
      relativePath: { eq: "shoutout/shoutout-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
